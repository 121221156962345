/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;

// Import ng-select
@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~@toast-ui/editor/dist/toastui-editor.css';

$custom-typography: mat.define-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $headline-1: mat.define-typography-level(96px, 100px, 300),
  $headline-2: mat.define-typography-level(60px, 64px, 300),
  $headline-3: mat.define-typography-level(48px, 52px, 400),
  $body-1: mat.define-typography-level(14px, 24px, 400),
  $body-2: mat.define-typography-level(14px, 20px, 400),
  $caption: mat.define-typography-level(12px, 16px, 400)
);

@include mat.all-component-typographies($custom-typography);
@include mat.all-component-densities(-1);

#vizContainer iframe {
  height: 700px !important;
}
.vizContainer #others {
  display: none;
}

.required-field-asteriks::after {
  content: '*';
}

.navbar-brand {
  height: 36px !important;
  top: 15px !important;
}

.ng-select.auto-grow {
  display: flex;

  .ng-select-container {
    min-width: 100px;
    width: 100%;
  }

  .ng-dropdown-panel {
    width: auto;
  }
}

.form-group-w300 {
  width: 300px !important;
}

.form-group-w150 {
  width: 150px !important;
}

.multiSelectClass {
  margin: 40px 0px;
}

/*
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
*/
